import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

//gambar
import AddOutlined from '@mui/icons-material/AddOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';

function General_Ledger(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [id_gl, setIdGl] = useState("");
    const [nama, setNama] = useState("");
    const [nomor, setNomor] = useState("");
    const [sumber_gl, setSumberGl] = useState([]);
    const [data_gl, setDataGl] = useState([]);
    const [data_parent, setDataParent] = useState([]);
    const [parent, setParent] = useState(null);
    const [input_parent, setInputParent] = useState("");
    const [status, setStatus] = useState(false);
    const [label_status, setLabelStatus] = useState("Nonaktif");
    const [status_simpan, setStatusSimpan] = useState("Simpan");

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/akuntansi/tampil_gl.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: ""
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberGl(data.data);
                    setDataGl(data.data);
                }
                else {
                    setSumberGl([]);
                    setDataGl([]);
                }
            })
            .catch(error => {
                setSumberGl([]);
                setDataGl([]);
            }
            );

        fetch(props.aplikasi + '/akuntansi/tampil_parent.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataParent(data.data);
                }
                else {
                    setDataParent([]);
                }
            })
            .catch(error => {
                setDataParent([]);
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_general_ledger = () => {
        fetch(props.aplikasi + '/akuntansi/tampil_gl.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: ""
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberGl(data.data);
                    setDataGl(data.data);
                }
                else {
                    setSumberGl([]);
                    setDataGl([]);
                }
            })
            .catch(error => {
                setSumberGl([]);
                setDataGl([]);
            }
            );

        fetch(props.aplikasi + '/akuntansi/tampil_parent.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataParent(data.data);
                }
                else {
                    setDataParent([]);
                }
            })
            .catch(error => {
                setDataParent([]);
            }
            );
    }

    const cari = (event) => {
        var cari = (event.target.value).toLowerCase();
        var awal = 1;
        var akhir = sumber_gl.length;

        var posisi = 0;
        var tampung = [];
        while (awal <= akhir) {
            var hasil = ((sumber_gl[awal - 1]["gl"]).toLowerCase()).indexOf(cari);

            if (hasil === -1) {
                hasil = ((sumber_gl[awal - 1]["id_gl"]).toLowerCase()).indexOf(cari);
            }

            if (hasil !== -1) {
                tampung[posisi] = sumber_gl[awal - 1];
                posisi++;
            }
            awal++;
        }

        setDataGl(tampung);
    }

    const bersih_input_gl = () => {
        setIdGl("");
        setNama("");
        setNomor("");
        setParent(null);
        setStatus(false);
        setLabelStatus("Nonaktif");
    }

    const simpan = () => {
        //10101 = kas
        //10301 = piutang
        //10501 = persediaan barang dagang
        //10601 = peralatan
        //10701 = akumulasi penyusutan
        //30401 = saldo laba / rugi
        //40101 = pendapatan operasional
        //50401 = biaya pemakaian bahan
        //50501 = biaya kerugian
        //50601 = biaya penyusutan
        //10198 = rak carwash

        if (id_gl.trim() === "10101" || id_gl.trim() === "10301" || id_gl.trim() === "10501" || id_gl.trim() === "10601" || id_gl.trim() === "10701" ||
            id_gl.trim() === "30401" || id_gl.trim() === "40101" || id_gl.trim() === "50401" || id_gl.trim() === "50501" || id_gl.trim() === "50601" ||
            id_gl.trim() === "10198") {
            setJenisNotif("warning");
            setIsiNotif("GL ini tidak dapat dimodifikasi");
            setTampilNotif(true);
            document.getElementById("nama").focus();
            return;
        }

        if (nama.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Nama akun wajib diisi");
            setTampilNotif(true);
            document.getElementById("nama").focus();
            return;
        }

        if (parent === null) {
            setJenisNotif("warning");
            setIsiNotif("Parent GL wajib diisi");
            setTampilNotif(true);
            document.getElementById("parent").focus();
            return;
        }

        if (nomor.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Nomor urut wajib diisi");
            setTampilNotif(true);
            document.getElementById("nomor").focus();
            return;
        }

        if (status_simpan === "Simpan") {
            fetch(props.aplikasi + '/akuntansi/simpan_gl.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            nama: nama,
                            parent: parent.id_parentgl,
                            nomor: nomor,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_gl();
                        tampil_general_ledger();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                    }
                    else {
                        tampil_general_ledger();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                    }
                })
                .catch(error => {
                    tampil_general_ledger();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                }
                );
        }
        else {
            fetch(props.aplikasi + '/akuntansi/simpan_gl.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            id_gl: id_gl,
                            nama: nama,
                            parent: parent.id_parentgl,
                            nomor: nomor,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        tampil_general_ledger();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                    }
                    else {
                        tampil_general_ledger();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                    }
                })
                .catch(error => {
                    tampil_general_ledger();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                }
                );
        }
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        bersih_input_gl();
                        setStatusSimpan("Simpan");
                        setTampilDialog(true);
                    }}
                >
                    <AddOutlined />
                </Fab>

                <Card
                    sx={{ padding: "10px" }}
                >
                    <TextField
                        id="input-with-icon-textfield"
                        label="Cari berdasarkan nomor atau nama general ledger"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                        onChange={cari}
                    />
                </Card>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_gl.map((data_gl) => {
                        return (
                            <Grid
                                item xs={12} sm={6} md={4} lg={3}
                                key={data_gl.id_gl}
                                onClick={() => {
                                    bersih_input_gl();
                                    setIdGl(data_gl.id_gl);
                                    setNama(data_gl.gl);

                                    var awal = 1;
                                    var akhir = data_parent.length;
                                    while (awal <= akhir) {
                                        if (data_parent[awal - 1]["id_parentgl"] === data_gl.jenis_gl) {
                                            setParent(data_parent[awal - 1]);
                                            break;
                                        }
                                        awal++;
                                    }

                                    if (data_gl.status === "1") {
                                        setStatus(true);
                                        setLabelStatus("Aktif");
                                    }
                                    else {
                                        setStatus(false);
                                        setLabelStatus("Nonaktif");
                                    }

                                    setNomor(data_gl.id_gl.substr(3, 5))

                                    setStatusSimpan("Perbarui");
                                    setTampilDialog(true);
                                }}
                            >
                                <Card>
                                    <List>
                                        <ListItem button>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body1" noWrap>{data_gl.id_gl + " - " + data_gl.gl}</Typography>
                                                }
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Input Data General Ledger</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Nama
                                    </Typography>
                                </Grid>

                                <Grid item xs={8} sm={8} md={9}>
                                    <TextField
                                        id="nama"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={nama}
                                        onChange={(event) => setNama(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Parent GL
                                    </Typography>
                                </Grid>

                                <Grid item xs={8} sm={8} md={9}>
                                    <Autocomplete
                                        id="parent"
                                        options={data_parent}
                                        getOptionLabel={(option) => option.id_parentgl + " - " + option.parentgl}
                                        inputValue={input_parent}
                                        value={parent}
                                        onInputChange={(e, nilai) => setInputParent(nilai)}
                                        onChange={(e, nilai) => setParent(nilai)}
                                        renderInput={(params) => <TextField {...params} key={params.id_parentgl} variant="outlined" size="small" />}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Nomor Urut
                                    </Typography>
                                </Grid>

                                <Grid item xs={8} sm={8} md={9}>
                                    <TextField
                                        id="nomor"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        InputProps={
                                            parent !== null ?
                                            {
                                                startAdornment: (
                                                    <InputAdornment position="end">
                                                        {parent.id_parentgl}
                                                    </InputAdornment>
                                                ),
                                            }
                                            :
                                            {}
                                        }
                                        value={nomor}
                                        onChange={(event) => setNomor(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Status
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={9}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={status}
                                                onChange={(event) => {
                                                    if (status === false) {
                                                        setStatus(true);
                                                        setLabelStatus("Aktif");
                                                    }
                                                    else {
                                                        setStatus(false);
                                                        setLabelStatus("Nonaktif");
                                                    }
                                                }}
                                            />
                                        }

                                        label={label_status}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        onClick={() => simpan()}
                    >
                        <Typography variant="body2" noWrap={true}>{status_simpan}</Typography>
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(General_Ledger);