import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

//gambar
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "rgba(248,246,255,255)",
        borderColor: "rgba(248,246,255,255)"
    },
}));

function Pindah_Saldo(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [saldo, setSaldo] = useState(0);
    const [saldo_pusat, setSaldoPusat] = useState(0);
    const [nominal, setNominal] = useState(0);
    const [data_obat, setDataObat] = useState([]);
    const [waktu, setWaktu] = useState("");
    const [tampil_dialog_hapus, setTampilDialogHapus] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/pengaturan/tampil_pindah_saldo.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSaldo(data.saldo);
                    setSaldoPusat(data.saldo_pusat);
                }
                else {
                    setSaldo(0);
                    setSaldoPusat(0);
                }
            })
            .catch(error => {
                setSaldo(0);
                setSaldoPusat(0);
            }
            );

        tampil_riwayat();
    }, [props.aplikasi]);

    const bersih = () => {
        fetch(props.aplikasi + '/pengaturan/tampil_pindah_saldo.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSaldo(data.saldo);
                    setSaldoPusat(data.saldo_pusat);
                }
                else {
                    setSaldo(0);
                    setSaldoPusat(0);
                }
            })
            .catch(error => {
                setSaldo(0);
                setSaldoPusat(0);
            }
            );

        setNominal(0);
    }

    const tampil_riwayat = () => {
        fetch(props.aplikasi + '/pengaturan/tampil_riwayat_pindah_saldo.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        cabang: "CARWASH"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataObat(data.data);
                }
                else {
                    setDataObat([]);
                }
            })
            .catch(error => {
                setDataObat([]);
            }
            );
    }

    const ubah_nominal = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setNominal(event.target.value);
    }

    const perbaiki_nominal = (event) => {
        if (parseFloat(event.target.value) > 0) {
            setNominal(event.target.value);
        }
        else {
            setNominal(0);
        }
    }

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const format_tanggal = (waktu, format) => {
        let tahun = waktu.substring(0, 4);
        let bulan = waktu.substring(5, 7);
        let tanggal = waktu.substring(8, 10);
        let jam = waktu.substring(11, 13);
        let menit = waktu.substring(14, 16);
        let detik = waktu.substring(17, 19);

        if (format === "short") {
            return tanggal + "-" + bulan + "-" + tahun;
        }
        else {
            return tanggal + "-" + bulan + "-" + tahun + " " + jam + ":" + menit + ":" + detik;
        }
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const switch_status = (param) => {
        switch (param) {
            case "R":
                return "Menunggu";
            case "A":
                return "Diterima";
            case "D":
                return "Ditolak";
            default:
                break;
        }
    }

    const simpan = () => {
        if (parseInt(nominal) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Nominal wajib diisi");
            setTampilNotif(true);
            document.getElementById("nominal").focus();
            return;
        }

        if (parseInt(nominal) > parseInt(saldo)) {
            setJenisNotif("warning");
            setIsiNotif("Nominal tidak boleh melebihi saldo kas besar");
            setTampilNotif(true);
            document.getElementById("nominal").focus();
            return;
        }

        setProses(true);
        fetch(props.aplikasi + '/pengaturan/simpan_pindah_saldo_pusat.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengirim: localStorage.getItem("id_pengguna"),
                        pengirim: localStorage.getItem("nama"),
                        nominal: nominal
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih();
                    tampil_riwayat();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const hapus = () => {
        setProses(true);
        fetch(props.aplikasi + '/pengaturan/hapus_pindah_saldo_pusat.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        cabang: "CARWASH",
                        waktu: waktu
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih();
                    tampil_riwayat();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Dialog
                open={tampil_dialog_hapus}
                onClose={() => setTampilDialogHapus(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus Karyawan
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus data ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogHapus(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => {
                            setTampilDialogHapus(false);
                            hapus();
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            backgroundColor: "#eff0f0",
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Card
                            sx={{ margin: "10px", padding: "10px" }}
                        >
                            <Grid container spacing={1}>
                                <Grid container item>
                                    <Grid item xs={4} sm={4} md={3} >
                                        <Typography variant="body2">
                                            Saldo Kas Besar
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={8} sm={8} md={9}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseInt(saldo))}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={4} sm={4} md={3} >
                                        <Typography variant="body2">
                                            Saldo Di Pusat
                                        </Typography    >
                                    </Grid>

                                    <Grid item xs={8} sm={8} md={9}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseInt(saldo_pusat))}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Nominal
                                        </Typography>
                                    </Grid>

                                    <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="nominal"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={nominal}
                                                onChange={(e) => ubah_nominal(e)}
                                                onBlur={(e) => perbaiki_nominal(e)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                                Rp. {format_rupiah(parseInt(nominal))}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={4} sm={4} md={3}>

                                    </Grid>

                                    <Grid item xs={8} sm={8} md={9}>
                                        <LoadingButton
                                            loadingPosition="start"
                                            variant="contained"
                                            color="primary"
                                            startIcon={<SaveOutlined />}
                                            sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                                            loading={proses}
                                            onClick={() => simpan()}
                                        >
                                            <Typography variant="body2" noWrap={true}>Simpan</Typography>
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            backgroundColor: "#eff0f0",
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Card
                            sx={{ margin: "10px", padding: "10px" }}
                        >
                            <Grid container spacing={1}>
                                <Grid container item>
                                    <Grid item xs={12} >
                                        <Typography variant="h6">
                                            Riwayat 10 Transaksi Terakhir
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} >
                                        <TableContainer component={Paper}>
                                            <Table stickyHeader size="small" aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="center">No.</StyledTableCell>
                                                        <StyledTableCell align="center">Tgl. & Jam</StyledTableCell>
                                                        <StyledTableCell align="center">Nominal</StyledTableCell>
                                                        <StyledTableCell align="center">Status</StyledTableCell>
                                                        <StyledTableCell align="center">Aksi</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        data_obat.map((tampung, index) =>
                                                            <>
                                                                <StyledTableRow key={index}>
                                                                    <TableCell size="small" align="center">
                                                                        <Typography variant="body2">
                                                                            {index + 1}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell size="small" align='center'>
                                                                        <Typography variant="body2">
                                                                            {format_tanggal(tampung.waktu_entry, "long")}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell size="small" align="right">
                                                                        <Typography variant="body2">
                                                                            {format_rupiah(parseFloat(tampung.nominal))}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell size="small" align='center'>
                                                                        <Typography variant="body2"
                                                                            sx={{
                                                                                color: (() => {
                                                                                    switch (tampung.status) {
                                                                                        case "R":
                                                                                            return "orange";
                                                                                        case "A":
                                                                                            return "green";
                                                                                        case "D":
                                                                                            return "red";
                                                                                        default:
                                                                                            return "black";
                                                                                    }
                                                                                })()
                                                                            }}
                                                                        >
                                                                            {switch_status(tampung.status)}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell size="small" align='center'>
                                                                        {tampung.status === "R" ?
                                                                            <LoadingButton
                                                                                loadingPosition="start"
                                                                                variant="contained"
                                                                                color="primary"
                                                                                startIcon={<DeleteIcon />}
                                                                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                                                                                loading={proses}
                                                                                onClick={() => {
                                                                                    setWaktu(tampung.waktu_entry);
                                                                                    setTampilDialogHapus(true);
                                                                                }}
                                                                            >
                                                                                <Typography variant="body2" noWrap={true}>Hapus</Typography>
                                                                            </LoadingButton>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </TableCell>
                                                                </StyledTableRow>
                                                            </>
                                                        )
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}

export default forwardRef(Pindah_Saldo);